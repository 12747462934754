import React, { Component, useEffect, useState } from "react";
import SEO from "../components/SEO";

/*
//50% chance
//now we have the diagonal line. Now time to randomize.
	//stroke - canvas emits ink
	Context: lets you draw.
	1. make new cancar
	2.stroke a line
	3. randomize the stroke
	4.start making tiles..
*/
const GenerativeArt = () => {
  const [count, SetCount] = useState(0);
  useEffect(() => {
    init();
  });
  const init = () => {
    let canvas = document.querySelector("canvas");
    let context = canvas && canvas.getContext("2d");
    // context.fillStyle = "blue";
    context.fillRect(10, 10, 100, 100);

    let size = "500";
    if (context !== null) {
      canvas.width = size;
      canvas.height = size;
    }

    context.clearRect(0, 0, canvas.width, canvas.height);
    const draw = (x, y, width, height) => {
      let leftToRight = Math.random() > 0.5;

      if (leftToRight) {
        context.moveTo(x, y);
        context.lineTo(x + width, y + height);
        context.stroke();
        context.strokeStyle = "grey";
      } else {
        context.moveTo(x + width, y);
        context.lineTo(x, y + height);
        context.stroke();
      }
    };

    // Update the document title using the browser API
    let step = 30;

    for (let x = 0; x < size; x += step) {
      for (let y = 0; y < size; y += step) {
        draw(x, y, step, step);
      }
    }
  };
  return (
    <div className="blackSection">
      <SEO />
      <div id="generativeArt" className="mainPage">
        <button data-type="mario" onClick={() => init()}>
          Shuffle me
        </button>
        <canvas id="canvas" />
      </div>
    </div>
  );
};
export default GenerativeArt;
